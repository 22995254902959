type QueryWithEntity = {
  route?:
    | {
        entity?:
          | {
              businessArea?: {
                key?: string | null
              }
            }
          | object
      }
    | object
}

/**
 * Extract the business area key from a route query.
 *
 * @see ~/pages/[language]/[...slug]/query.route.graphql
 */
function getBusinessAreaKeyFromRouteQuery(
  routeQuery: QueryWithEntity | undefined | null,
): string {
  if (
    routeQuery &&
    routeQuery.route &&
    'entity' in routeQuery.route &&
    routeQuery.route?.entity &&
    // eslint-disable-next-line no-unsafe-optional-chaining
    'businessArea' in routeQuery.route?.entity
  ) {
    return routeQuery.route?.entity?.businessArea?.key || 'SV_GROUP'
  }
  return 'SV_GROUP'
}

/**
 * Returns the current business area key.
 */
export function useBusinessArea() {
  return useState<string>('businessAreaKey', () => '')
}

/**
 * Set the business area key for the current page.
 */
export function setBusinessAreaFromRoute(
  routeQuery: QueryWithEntity | undefined | null,
) {
  const key = useBusinessArea()
  key.value = getBusinessAreaKeyFromRouteQuery(routeQuery)
}

/**
 * Set the business area key directly.
 */
export function setBusinessArea(newkey?: string) {
  const key = useBusinessArea()
  key.value = newkey || 'SV_GROUP'
}
